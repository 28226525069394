import { getPageIdFromPath } from '@zyro-inc/site-modules/utils/page/getPageIdFromPath';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { getOptimizedSrc } from '@zyro-inc/site-modules/utils/getSrcsets';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';
import {
	DEFAULT_HTML_LANG_VALUE,
	SYSTEM_LOCALE,
	PREVIEW_SUBDOMAINS,
} from '@zyro-inc/site-modules/constants';

import {
	DEFAULT_FAVICON,
	ZYROSITE_SUBDOMAINS,
} from './constants';

export const getHeadData = ({
	siteData,
	path = '/',
}) => {
	if (!siteData) {
		return {};
	}

	const {
		meta,
		languages,
		domain,
		siteId,
	} = siteData;

	const currentPageId = getPageIdFromPath({
		siteData,
		path,
	});

	const currentLocale = getPathParams({
		path,
		languages,
		defaultLocale: meta.defaultLocale,
	})?.locale;

	const {
		pages,
		blocks,
		elements,
		metaTitle,
	} = languages[currentLocale];

	const pageBlocks = pages[currentPageId]?.blocks.reduce((blocksArray, blockId) => [
		...blocksArray,
		blocks[blockId],
	], []);

	const pageElements = pageBlocks?.reduce((allElements, block) => [
		...allElements,
		...(block?.components?.map((elementId) => elements[elementId]) ?? []),
	], []);

	const pageVideoElements = pageElements?.filter((elementData) => elementData?.type === 'GridVideo');

	const hasMatchingVideoSrc = (text) => pageVideoElements?.some((elementData) => elementData?.settings?.src.includes(text));

	const {
		faviconOrigin,
		faviconPath,
		metaHtmlLanguage,

		metaTitle: siteMetaTitle,
		ogImageOrigin: siteOgImageOrigin,
		ogImagePath: siteOgImagePath,
		ogImageAlt: siteOgImageAlt,
	} = meta;

	const {
		name: pageName,
		meta: pageMeta,
	} = pages[currentPageId] ?? {};

	const {
		title: pageMetaTitle,
		description: pageMetaDescription,
		ogImageOrigin: pageOgImageOrigin,
		ogImagePath: pageOgImagePath,
		ogImageAlt: pageOgImageAlt,
		noindex: pageNoindex,
	} = pageMeta ?? {};

	// siteName is used both as a suffix for page title and also as meta og tag

	const siteName = metaTitle || siteMetaTitle;
	const pageTitle = pageMetaTitle || pageName;
	const title = siteName ? `${pageTitle} | ${siteName}` : pageTitle;

	const currentSeoLocale = currentLocale === SYSTEM_LOCALE ? undefined : currentLocale;

	const lang = currentSeoLocale || metaHtmlLanguage || DEFAULT_HTML_LANG_VALUE.value;

	const ogImageOrigin = pageOgImageOrigin || siteOgImageOrigin;
	const ogImagePath = pageOgImagePath || siteOgImagePath;
	const ogImageAlt = pageOgImageAlt || siteOgImageAlt;

	const ogImageUrl = getOptimizedSrc(ogImageOrigin, ogImagePath, siteId, {
		width: 1200,
		height: 630,
		format: 'jpeg',
	});

	const faviconUrl = getOptimizedSrc(faviconOrigin, faviconPath, siteId, {
		width: 32,
		height: 32,
		format: 'png',
	}) ?? DEFAULT_FAVICON;

	const isFreeDomainOrigin = ZYROSITE_SUBDOMAINS.some((zyrositeDomain) => window.origin.includes(zyrositeDomain));
	const isPreviewDomainOrigin = PREVIEW_SUBDOMAINS.some((previewDomain) => window.origin.includes(previewDomain));
	const isSiteEngineOrigin = window.origin.includes(import.meta.env.VITE_PREVIEW_URL);

	const canonicalUrl = domain
		? window.location.href.replace(window.location.host, domain)
		: window.location.href;
	const noindex = !!pageNoindex || (domain && (isFreeDomainOrigin || isPreviewDomainOrigin) && !isAppPrerendering);
	const redirectToCanonical = import.meta.env.PROD
			&& domain
			&& domain !== window.location.hostname
			&& !isFreeDomainOrigin
			&& !isPreviewDomainOrigin
			&& !isAppPrerendering
			&& !getIsInIframe()
			&& !isSiteEngineOrigin;

	return {
		title,
		description: pageMetaDescription,
		lang,
		siteName,
		ogImageAlt,
		ogImageUrl,
		faviconUrl,
		redirectToCanonical,
		canonicalUrl,
		noindex,

		hasEcwid: pageBlocks?.some((elementData) => elementData?.type === 'BlockEcwidStore'),
		hasInstagram: pageElements?.some((elementData) => elementData?.type === 'GridInstagramFeed'),
		hasMap: pageElements?.some((elementData) => elementData?.type === 'GridMap'),
		hasMessenger: !!meta.facebookMessenger,
		hasVimeo: hasMatchingVideoSrc('vimeo.com'),
		hasYoutube: hasMatchingVideoSrc('youtube.com'),
	};
};
